<template>
    <div>
        <v-container
            id="dashboard"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col cols="12" md="7" class="d-flex">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Reports and Analytics</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedDashboardPage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="dashboardItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>

            <v-container
                fluid
                class="mt-n4 flex-grow-1 fill-height align-start justify-center"
            >
                <v-container
                    fluid
                    class="mt-4 flex-grow-1 fill-height align-start justify-center pa-0 d-flex"
                >
                    <v-row>
                        <v-col cols="12" md="12" class="d-flex">
                            <v-card
                                flat
                                width="100%"
                                class="pa-4 pb-6 card-shadow"
                            >
                                <v-row>
                                    <v-col cols="12" md="4" sm="4" xs="4">
                                        <v-card-title
                                            class="font-size-24"
                                            style="font-weight: bold"
                                            >All</v-card-title
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="8"
                                        sm="8"
                                        xs="8"
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                            align-items: center;
                                        "
                                        class="pr-8"
                                    >
                                        <DateTimeFilter
                                            @changeMonthFilter="() => {}"
                                            @changeHourFilter="() => {}"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="px-8">
                                    <v-card
                                        rounded
                                        flat
                                        class="custom-info-card px-1 pb-3 mr-5"
                                    >
                                        <div class="upper-card-info">
                                            <v-card-title
                                                class="display-1 font-weight-bold"
                                                >123</v-card-title
                                            >
                                            <v-chip
                                                class=""
                                                label
                                                color="#D1FCE7"
                                            >
                                                <v-icon>mdi-trending-up</v-icon>
                                                <span class="ml-1">12.13%</span>
                                            </v-chip>
                                        </div>
                                        <span class="ml-4 card-info-title"
                                            >Total Sessions</span
                                        >
                                    </v-card>
                                    <v-card
                                        rounded
                                        flat
                                        class="custom-info-card px-1 pb-3 mr-5"
                                    >
                                        <div class="upper-card-info">
                                            <v-card-title
                                                class="display-1 font-weight-bold"
                                                >123</v-card-title
                                            >
                                            <v-chip
                                                class=""
                                                label
                                                color="#D1FCE7"
                                            >
                                                <v-icon>mdi-trending-up</v-icon>
                                                <span class="ml-1">12.13%</span>
                                            </v-chip>
                                        </div>
                                        <span class="px-4 card-info-title"
                                            >Total Number of Groups</span
                                        >
                                    </v-card>
                                </v-row>
                                <v-row class="py-4 px-8 text-center">
                                    <v-col cols="12" md="12">
                                        <div
                                            class="d-flex justify-center align-center"
                                        >
                                            <!-- <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular> -->
                                        </div>
                                        <LineChart
                                            :datasets="[
                                                {
                                                    label: 'Total Number of groups',
                                                    data: [
                                                        20, 40, 30, 50, 40, 60,
                                                        50, 70, 60, 80, 70, 90,
                                                    ],
                                                    borderColor: '#4200FF',
                                                    backgroundColor: '#4200FF',
                                                    pointBorderWidth: '5',
                                                    pointBackgroundColor:
                                                        '#4200FF',
                                                    pointBorderColor:
                                                        'rgba(151, 151, 151, 0.500)',
                                                    borderWidth: '2',
                                                },
                                                {
                                                    label: 'Total Sessions',
                                                    data: [
                                                        40, 30, 20, 70, 80, 90,
                                                        70, 60, 40, 50, 30, 40,
                                                    ],
                                                    borderColor: '#FD2424',
                                                    backgroundColor: '#FD2424',
                                                    pointBorderWidth: '5',
                                                    pointBackgroundColor:
                                                        '#FD2424',
                                                    pointBorderColor:
                                                        'rgba(151, 151, 151, 0.500)',
                                                    borderWidth: '2',
                                                },
                                            ]"
                                        />
                                        <!-- <span>No changes for this filter.</span> -->
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container
                    fluid
                    class="mt-4 flex-grow-1 fill-height align-start justify-center pa-0 d-flex"
                >
                    <v-row>
                        <v-col cols="12" md="12" class="d-flex">
                            <v-card
                                flat
                                width="100%"
                                class="pa-4 pb-6 card-shadow"
                            >
                                <v-row>
                                    <v-col cols="12" md="4" sm="4" xs="4">
                                        <v-card-title
                                            class="font-size-24"
                                            style="font-weight: bold"
                                            >All</v-card-title
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="8"
                                        sm="8"
                                        xs="8"
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                            align-items: center;
                                        "
                                        class="pr-8"
                                    >
                                        <DateTimeFilter
                                            @changeMonthFilter="() => {}"
                                            @changeHourFilter="() => {}"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="px-8">
                                    <v-card
                                        rounded
                                        flat
                                        class="custom-info-card px-4 pb-3 mr-5"
                                    >
                                        <div class="upper-card-info">
                                            <v-card-title
                                                class="display-1 font-weight-bold"
                                                >123</v-card-title
                                            >
                                            <v-chip
                                                class=""
                                                label
                                                color="#D1FCE7"
                                            >
                                                <v-icon>mdi-trending-up</v-icon>
                                                <span class="ml-1">12.13%</span>
                                            </v-chip>
                                        </div>
                                        <span class="px-4 card-info-title"
                                            >Avg sessions created per
                                            group</span
                                        >
                                    </v-card>
                                    <v-card
                                        rounded
                                        flat
                                        class="custom-info-card px-1 pb-3 mr-5"
                                    >
                                        <div class="upper-card-info">
                                            <v-card-title
                                                class="display-1 font-weight-bold"
                                                >123</v-card-title
                                            >
                                            <v-chip
                                                class=""
                                                label
                                                color="#D1FCE7"
                                            >
                                                <v-icon>mdi-trending-up</v-icon>
                                                <span class="ml-1">12.13%</span>
                                            </v-chip>
                                        </div>
                                        <span class="px-4 card-info-title"
                                            >Total Number of groups</span
                                        >
                                    </v-card>
                                </v-row>
                                <v-row class="py-4 px-8 text-center">
                                    <v-col cols="12" md="12">
                                        <div
                                            class="d-flex justify-center align-center"
                                        >
                                            <!-- <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular> -->
                                        </div>
                                        <BarChart
                                            :isHorizontal="true"
                                            :labels="[
                                                'Group 1',
                                                'Group 2',
                                                'Group 3',
                                                'Group 4',
                                                'Group 5',
                                                'Group 6',
                                            ]"
                                            :display="true"
                                            :datasets="[
                                                {
                                                    label: 'Avg sessions created',
                                                    data: [
                                                        20, 40, 30, 50, 40, 60,
                                                    ],
                                                    backgroundColor: (ctx) => {
                                                        const canvas =
                                                            ctx.chart.ctx;
                                                        const gradient =
                                                            canvas.createLinearGradient(
                                                                0,
                                                                0,
                                                                0,
                                                                400
                                                            );

                                                        gradient.addColorStop(
                                                            1,
                                                            '#725CFA'
                                                        );
                                                        gradient.addColorStop(
                                                            0,
                                                            '#EF7BE3'
                                                        );

                                                        return gradient;
                                                    },
                                                },
                                                {
                                                    label: 'Group Attendance',
                                                    data: [
                                                        40, 50, 29, 80, 40, 20,
                                                    ],
                                                    backgroundColor: (ctx) => {
                                                        const canvas =
                                                            ctx.chart.ctx;
                                                        const gradient =
                                                            canvas.createLinearGradient(
                                                                0,
                                                                0,
                                                                0,
                                                                400
                                                            );

                                                        gradient.addColorStop(
                                                            1,
                                                            '#EF7BE3'
                                                        );
                                                        gradient.addColorStop(
                                                            0,
                                                            '#FF5A5A'
                                                        );

                                                        return gradient;
                                                    },
                                                },
                                            ]"
                                        />
                                        <!-- <span>No changes for this filter.</span> -->
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container
                    fluid
                    class="mt-4 flex-grow-1 fill-height align-start justify-center pa-0 d-flex"
                >
                    <v-row>
                        <v-col cols="8" md="8">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-card
                                        flat
                                        width="100%"
                                        class="pa-4 pb-6 card-shadow"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="4"
                                                sm="4"
                                                xs="4"
                                            >
                                                <v-card-title
                                                    class="font-size-24"
                                                    style="font-weight: bold"
                                                    >All</v-card-title
                                                >
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="8"
                                                sm="8"
                                                xs="8"
                                                style="
                                                    display: flex;
                                                    justify-content: flex-end;
                                                    align-items: center;
                                                "
                                                class="pr-8"
                                            >
                                                <DateTimeFilter
                                                    @changeMonthFilter="() => {}"
                                                    @changeHourFilter="() => {}"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-8">
                                            <v-card
                                                rounded
                                                flat
                                                class="custom-info-card px-4 pb-3 mr-5"
                                            >
                                                <div class="upper-card-info">
                                                    <v-card-title
                                                        class="display-1 font-weight-bold"
                                                        >123</v-card-title
                                                    >
                                                    <v-chip
                                                        class=""
                                                        label
                                                        color="#D1FCE7"
                                                    >
                                                        <v-icon
                                                            >mdi-trending-up</v-icon
                                                        >
                                                        <span class="ml-1"
                                                            >12.13%</span
                                                        >
                                                    </v-chip>
                                                </div>
                                                <span
                                                    class="px-4 card-info-title"
                                                    >Avg sessions attended per
                                                    user</span
                                                >
                                            </v-card>
                                            <v-card
                                                rounded
                                                flat
                                                class="custom-info-card px-1 pb-3 mr-5"
                                            >
                                                <div class="upper-card-info">
                                                    <v-card-title
                                                        class="display-1 font-weight-bold"
                                                        >123</v-card-title
                                                    >
                                                    <v-chip
                                                        class=""
                                                        label
                                                        color="#D1FCE7"
                                                    >
                                                        <v-icon
                                                            >mdi-trending-up</v-icon
                                                        >
                                                        <span class="ml-1"
                                                            >12.13%</span
                                                        >
                                                    </v-chip>
                                                </div>
                                                <span
                                                    class="px-4 card-info-title"
                                                    >Avg sessions missed per
                                                    user</span
                                                >
                                            </v-card>
                                        </v-row>
                                        <v-row class="py-4 text-center">
                                            <v-col cols="12" md="12">
                                                <div
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <!-- <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular> -->
                                                </div>
                                                <LineChart
                                                    :labels="[
                                                        'Mon',
                                                        'Tues',
                                                        'Wed',
                                                        'Thu',
                                                        'Fri',
                                                        'Sat',
                                                        'Sun',
                                                    ]"
                                                    :datasets="[
                                                        {
                                                            data: [
                                                                20, 40, 50, 60,
                                                                70, 50, 40,
                                                            ],
                                                            borderColor:
                                                                '#1ED6FF',
                                                            backgroundColor: (
                                                                ctx
                                                            ) => {
                                                                const canvas =
                                                                    ctx.chart
                                                                        .ctx;
                                                                const gradient =
                                                                    canvas.createLinearGradient(
                                                                        0,
                                                                        0,
                                                                        0,
                                                                        400
                                                                    );

                                                                gradient.addColorStop(
                                                                    0,
                                                                    '#1ED6FF'
                                                                );
                                                                gradient.addColorStop(
                                                                    1,
                                                                    'rgba(30, 214, 255, 0.2)'
                                                                );

                                                                return gradient;
                                                            },
                                                            pointBorderWidth:
                                                                '5',
                                                            pointBackgroundColor:
                                                                '#1ED6FF',
                                                            pointBorderColor:
                                                                '#1ed6ff73',
                                                            borderWidth: '2',
                                                        },
                                                        {
                                                            data: [
                                                                10, 30, 40, 50,
                                                                40, 30, 20,
                                                            ],
                                                            borderColor:
                                                                '#36F097',
                                                            backgroundColor: (
                                                                ctx
                                                            ) => {
                                                                const canvas =
                                                                    ctx.chart
                                                                        .ctx;
                                                                const gradient =
                                                                    canvas.createLinearGradient(
                                                                        0,
                                                                        0,
                                                                        0,
                                                                        400
                                                                    );

                                                                gradient.addColorStop(
                                                                    0,
                                                                    '#36F097'
                                                                );
                                                                gradient.addColorStop(
                                                                    1,
                                                                    'rgba(30, 214, 255, 0.2)'
                                                                );

                                                                return gradient;
                                                            },
                                                            pointBorderWidth:
                                                                '5',
                                                            pointBackgroundColor:
                                                                '#1ED6FF',
                                                            pointBorderColor:
                                                                '#1ed6ff73',
                                                            borderWidth: '2',
                                                        },
                                                    ]"
                                                />
                                                <!-- <span
                                                    >No changes for this
                                                    filter.</span
                                                > -->
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4" md="4">
                            <v-card
                                flat
                                width="100%"
                                class="pa-4 pb-6 card-shadow"
                                style="min-height: 100% !important"
                            >
                                <v-row>
                                    <v-col cols="12" md="12" sm="12" xs="12">
                                        <v-card-title
                                            class="font-size-24"
                                            style="font-weight: bold"
                                            >Most Attended Session</v-card-title
                                        >
                                    </v-col>
                                </v-row>
                                <v-row class="py-0">
                                    <v-col
                                        cols="12"
                                        md="12"
                                        sm="12"
                                        xs="12"
                                        style="
                                            display: flex;
                                            justify-content: flex-start;
                                            margin-top: -1em;
                                        "
                                        class="pl-7 py-0"
                                    >
                                        <DateTimeFilter
                                            @changeMonthFilter="() => {}"
                                            @changeHourFilter="() => {}"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="py-4 px-8 text-center">
                                    <v-col cols="12" md="12">
                                        <div
                                            class="d-flex justify-center align-center"
                                        >
                                            <!-- <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular> -->
                                        </div>
                                        <BarListItem v-for="item in 4" :key="item"/>
                                        <!-- <span>No changes for this filter.</span> -->
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
        </v-container>
    </div>
</template>

<script>
import BarChart from "@/components/Charts/Bar";
import LineChart from "@/components/Charts/Line";
import DateTimeFilter from "../components/DateTimeFilter.vue";
import BarListItem from "../components/BarListItem.vue";
export default {
    name: "dashboard",

    components: { BarChart, LineChart, DateTimeFilter, BarListItem },

    data() {
        return {
            selectedDashboardPage: "/dashboard/session",
            dashboardItems: [
                {
                    text: "Users",
                    value: "/dashboard/users",
                },
                {
                    text: "App Usage",
                    value: "/dashboard/app-usage",
                },
                {
                    text: "Session",
                    value: "/dashboard/session",
                },
            ],
        };
    },
    methods: {
        changeView(to) {
            this.$router.push(to);
        },
    },
};
</script>

<style lang="scss">
.custom-info-card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    min-width: 250px;
}

.card-shadow {
    box-shadow: 1px 1px 6px rgba(43, 53, 116, 0.08) !important;
    min-height: 500px !important;
}

.upper-card-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 15px 0 0;
}

.card-info-title {
    color: gray;
    font-size: 18px;
}
</style>
