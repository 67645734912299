<template>
    <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
);

export default {
    name: "BarChart",

    components: {
        Bar,
    },
    props: {
        chartId: {
            type: String,
            default: "bar-chart",
        },
        datasetIdKey: {
            type: String,
            default: "label",
        },
        backgroundColors: {
            type: Array,
            default: () => [],
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        cssClasses: {
            default: "",
            type: String,
        },
        styles: {
            type: Object,
            default: () => {},
        },
        plugins: {
            type: Object,
            default: () => {},
        },
        data: {
            type: Array,
            default: () => [],
        },
        isHorizontal: {
            type: Boolean,
            default: false,
        },
        display: {
            type: Boolean,
            default: false
        },
        labels: {
            type: Array,
            default: () => [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
        },
        datasets: {
            type: Array,
            default: () => [
                {
                    label: "Data One",
                    data: [20, 40, 30, 50, 40, 60, 50, 70, 60, 80, 70, 90],
                    backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(
                            0,
                            0,
                            0,
                            400
                        );

                        gradient.addColorStop(1, "#5A3FFF");
                        gradient.addColorStop(0, "rgba(30, 214, 255, 0.2)");

                        return gradient;
                    },
                },
            ],
        },
    },
    computed: {
        indexAxis() {
            return this.isHorizontal;
        },
    },
    data() {
        return {
            gradient: null,
            chartData: {
                labels: this.labels,
                datasets: this.datasets,
                // labels: [
                //     "January",
                //     "February",
                //     "March",
                //     "April",
                //     "May",
                //     "June",
                //     "July",
                //     "August",
                //     "September",
                //     "October",
                //     "November",
                //     "December",
                // ],
                // datasets: [
                //     {
                //         label: "Data One",
                //         backgroundColor: "#f87979",
                //         data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                //     },
                //     {
                //         label: "Data Two",
                //         backgroundColor: "#4200FF",
                //         data: [60, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                //     },
                // ],
            },
            chartOptions: {
                indexAxis: this.isHorizontal ? "y" : "x",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: this.display,
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                    },
                },
            },
        };
    },
};
</script>
